export default Object.freeze({
  EVENT_PAYMENT_CREATED: "payment.created",
  EVENT_PAYMENT_FAILED: "payment.failed",

  EVENT_PACKAGE_CREATED: "package.created",

  EVENT_SUBSCRIBER_CREATED: "subscriber.created",
  EVENT_SUBSCRIBER_PENDING_ACTIVATION: "subscriber.pending_activation",
  EVENT_SUBSCRIBER_ACTIVATED: "subscriber.activated",
  EVENT_SUBSCRIBER_CONFIRM_PACKAGE_CHANGE: "subscriber.confirm_package_change",
  EVENT_SUBSCRIBER_PACKAGE_CHANGED: "subscriber.package_changed",
  EVENT_SUBSCRIBER_CONFIRM_CANCEL: "subscriber.confirm_cancel",
  EVENT_SUBSCRIBER_CANCELLED: "subscriber.cancelled"
})