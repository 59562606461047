<template>
  <div
    class="
      h-full
      min-h-screen
      items-center
      flex flex-col
      bg-gray-800
      justify-center
    "
  >
    <div
      class="
        pb-8
        mt-12
        w-11/12
        max-w-full
        bg-white
        rounded-lg
        text-center
        sm:max-w-lg
        items-center
        border border-gray-200
      "
    >
      <!-- Redirect Unsuccess -->
      <div v-if="$route.query.message != null">
        <div class="px-8 mt-8">
          <!-- Status Logo -->
          <lottie-animation
            :path="'lottie/error-lottie.json'"
            :loop="false"
            :autoPlay="true"
            :speed="0.8"
            :width="'80'"
          />
        </div>

        <!-- Subscription Message -->
        <div class="mt-4 mb-5 text-xl px-8">
          {{ $route.query.message }}
        </div>
      </div>

      <!-- Redirect Pending -->
      <div
        v-else-if="
          $route.query.event ==
          WEBHOOK_EVENT.EVENT_SUBSCRIBER_PENDING_ACTIVATION
        "
      >
        <div class="px-8 mt-8">
          <!-- Status Logo -->
          <lottie-animation
            :path="'lottie/pending-lottie.json'"
            :loop="false"
            :autoPlay="true"
            :speed="0.8"
            :width="'80'"
          />
        </div>

        <!-- Subscription Message -->
        <div class="mt-4 mb-5 text-xl px-8">
          <p
            v-html="
              $t('subscription.redirect.pending_description', {
                package_name: $route.query.package_name,
              })
            "
          />
        </div>
      </div>

      <!-- Subscription Success -->
      <div
        v-else-if="
          $route.query.event == WEBHOOK_EVENT.EVENT_SUBSCRIBER_ACTIVATED
        "
      >
        <!-- Subscription Logo -->
        <div class="p-10 ml-5 flex place-content-center">
          <app-receipt-subscription />
        </div>

        <!-- Subscription Message -->
        <div class="mt-4 p-4 text-2xl font-semibold">
          {{ $t("subscription.redirect.message") }}
        </div>

        <!-- Subscription Description -->
        <p
          class="px-8"
          v-html="
            $t('subscription.redirect.description', {
              package_name: $route.query.package_name,
            })
          "
        />
      </div>

      <!-- Subscription Change Package Confirmation -->
      <div
        v-else-if="
          $route.query.event ==
          WEBHOOK_EVENT.EVENT_SUBSCRIBER_CONFIRM_PACKAGE_CHANGE
        "
      >
        <div :class="['px-8', $route.query.message != null ? 'mt-8' : '']">
          <!-- Status Logo -->
          <lottie-animation
            :path="
              $route.query.message != null
                ? 'lottie/error-lottie.json'
                : 'lottie/success-lottie.json'
            "
            :loop="false"
            :autoPlay="true"
            :speed="0.8"
            :width="$route.query.message != null ? '80' : '180'"
          />
        </div>

        <!-- Subscription Message -->
        <div class="mt-4 mb-5 text-xl px-8">
          {{
            $route.query.message ??
            $t("subscription.redirect.change_package_title")
          }}
        </div>
        <!-- Subscription Description -->
        <p
          class="px-8"
          v-html="
            $t('subscription.redirect.change_package_desc', {
              package_name: $route.query.package_name,
            })
          "
        />
      </div>

      <!-- Subscription Change Package Confirmation -->
      <div
        v-else-if="
          $route.query.event == WEBHOOK_EVENT.EVENT_SUBSCRIBER_CONFIRM_CANCEL
        "
      >
        <!-- Subscription Message -->
        <div class="mt-4 mb-5 text-xl px-8">
          {{ $t("subscription.redirect.cancel_title") }}
        </div>
        <!-- Subscription Description -->
        <p
          class="px-8"
          v-html="
            $t('subscription.redirect.cancel_desc', {
              package_name: $route.query.package_name,
            })
          "
        />
      </div>

      <!-- Test Mode -->
      <div class="flex justify-center w-full px-8 py-4">
        <app-test-mode-badge :testMode="testMode" />
      </div>

      <!-- Receipt Items -->
      <div
        class="px-8 my-5"
        v-for="(item, index) in receipt_items"
        :key="item.title"
      >
        <div class="flex justify-between my-2 space-x-2">
          <span class="font-semibold text-left">
            {{ item.title }}
          </span>
          <span class="text-right text-gray-500 break-all">
            {{ item.value }}
          </span>
        </div>
        <hr
          v-if="index < receipt_items.length - 1"
          class="mt-5 border-b border-gray-100"
        />
      </div>

      <!-- Business Info -->

      <div v-if="$route.query.logo_url || $route.query.company_name">
        <div class="flex justify-center pt-4">
          {{ $t("subscription.redirect.subscribed_to") }}
        </div>

        <hr class="mx-8 mt-3 border-b border-gray-100" />

        <div class="px-8 my-6">
          <div
            class="
              flex flex-wrap
              justify-center
              items-center
              mt-5
              space-x-0 space-y-5
              sm:space-x-8 sm:space-y-0
            "
          >
            <img
              v-if="$route.query.logo_url != null"
              :src="$route.query.logo_url"
              class="col-span-1 h-24 mb-3"
            />
            <div
              class="
                py-2
                px-6
                w-full
                sm:w-7/12
                rounded-md
                break-words
                text-gray-600
                border border-gray-300
              "
            >
              <div class="flex justify-center">
                <app-text-icon
                  :text="$route.query.company_name"
                  icon_outline="BriefcaseIcon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Close Windows -->
      <div>
        <p class="text-sm">
          {{ $t("subscription.redirect.close_window") }}
        </p>
      </div>
    </div>
    <div class="py-8">
      <app-logo-power-by />
    </div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import WEBHOOK_EVENT from "@/utils/const/webhook_event";

export default {
  components: {
    LottieAnimation,
  },
  data() {
    return {
      WEBHOOK_EVENT: WEBHOOK_EVENT,
      testMode: false,
    };
  },
  beforeCreate() {
    if (!this.$route.query.package_id) {
      window.location.href = "/not-found";
    }
  },
  mounted() {
    if (this.$route.query.test != null && this.$route.query.test == true) {
      this.testMode = true;
    }

    this.$store.commit("businessStore/setTestModePublicPage", this.testMode);
  },
  computed: {
    receipt_items() {
      let query = this.$route.query;

      return [
        {
          title: this.$t("subscription.redirect.subscription_name"),
          value: query.package_name ?? "-",
        },
        {
          title: this.$t("subscription.redirect.subscription_plan"),
          value:
            query.package_frequency_num == 1
              ? this.$lodash.startCase(query.package_frequency) ?? "-"
              : (this.$lodash.startCase(query.package_frequency) ?? "-") +
                (" (" + query.package_frequency_num + ")" ?? " "),
        },
        {
          title: this.$t("subscription.redirect.subscription_date_started"),
          value: this.getStartAt() ?? "-",
        },
        {
          title: this.$t("subscription.redirect.subscription_price"),
          value: this.$formats.currency(
            query.package_currency,
            query.package_amount ?? 0.0
          ),
        },
      ];
    },
  },
  methods: {
    getStartAt() {
      if (
        this.$route.query.event !=
        WEBHOOK_EVENT.EVENT_SUBSCRIBER_CONFIRM_PACKAGE_CHANGE
      ) {
        return this.$route.query.subscriber_start_at;
      }

      if (this.$route.query.subscriber_last_charge_at == null) {
        return null;
      }

      const lastChargeAt = this.$moment(
        this.$route.query.subscriber_last_charge_at,
        "DD-MM-YYYY"
      );
      const startAt = this.$moment(
        this.$route.query.subscriber_start_at,
        "DD-MM-YYYY"
      );
      const today = this.$moment();

      if (lastChargeAt.isSame(today, "day")) {
        return this.$route.query.subscriber_last_charge_at;
      }

      if (startAt.isAfter(today, "day")) {
        return this.$route.query.subscriber_start_at;
      }

      return this.$route.query.subscriber_start_at;
    },
  },
};
</script>